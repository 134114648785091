export default [
    // {
    //     path: '/',
    //     children: [
    //         {
    //             path: '',
    //             component: MainPage,
    //             name: 'index',
    //         },
    //         {
    //             path: 'contacts',
    //             component: Contacts,
    //             name: 'contacts.list',
    //         },
    //         {
    //             path: 'privacy-policy',
    //             component: PrivacyPolicy,
    //             name: 'privacy-policy.show',
    //         },
    //         ...projects,
    //         ...promotions,
    //         ...articles,
    //         ...portfolio,
    //     ],
    // },
]
